<template>
  <el-dialog
    :title="$t('立即支付')"
    top="5%"
    :visible.sync="showMask"
    width="600px"
  >
    <div class="applyBox">
      <el-form size="small" ref="ruleForm" label-width="140px">
        <el-form-item :label="$t('汇款银行：')">
          <el-select
            v-model="applyForm.pay_bank"
            @change="bankChange"
            :placeholder="$t('请选择您要使用的币种')"
          >
            <el-option
              v-for="item in bankInfo"
              :key="item.id"
              :label="item.bank_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('my_currencyPayment_1')">
          <div class="form-lable">
            {{ currencyData.bank_account }}
          </div>
        </el-form-item> -->
        <el-form-item :label="$t('收款账户：')">
          <div class="form-lable">
            {{ currencyData.bank_account }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('my_currencyPayment_4')">
          <div class="form-lable">
            {{ currencyData.bank_address }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('my_currencyPayment_6')">
          <div class="form-lable">
            {{ currencyData.bank_deposit }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('my_currencyPayment_8')">
          <div class="form-lable">
            {{ currencyData.swift_code }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('my_currencyPayment_10')">
          <div class="form-lable">
            {{ currencyData.deposit_address }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('订单编号：')">
          <div class="form-lable">{{ payData.order_num }}</div>
        </el-form-item>
        <el-form-item :label="$t('剩余支付时间：')">
          <div class="downTime">{{ downTime }}</div>
        </el-form-item>
      </el-form>
      <div class="tip">
        {{ $t('my_currencyPayment_12') }}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="showMask = false">
        {{ $t('my_currencyPayment_13') }}
      </el-button>
      <el-button
        size="small"
        :loading="nextLoading"
        type="primary"
        @click="paySet"
      >
        {{ $t('my_currencyPayment_14') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Login',
  props: {
    isshow: {
      type: Boolean,
    },
    payData: {
      type: Object,
    },
  },
  watch: {
    isshow(val) {
      this.showMask = val
      if (val === true) {
        this.getSeconds()
      } else {
        clearInterval(this.timeCler)
      }
    },
    showMask(val) {
      this.$emit('update:isshow', val)
    },
  },
  data() {
    return {
      applyForm: {
        pay_bank: '',
      },
      bankInfo: [],
      currencyData: {},
      showMask: false,
      nextLoading: false,
      timeCler: '',
      seconds: 0,
      downTime: '',
    }
  },
  created() {
    this.getBankInfo()
  },
  methods: {
    async getSeconds() {
      let res = await this.$axios.post('/api/Config/countDown', {
        order_id: this.payData.order_id,
      })
      if (res.code === 1) {
        this.seconds = res.data * 60
        this.countDown()
        this.Time()
      }
    },
    countDown() {
      if (this.seconds == 0) {
        this.downTime = '已失效,请重新申请'
        clearInterval(this.timeCler)
        return
      }
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.downTime = m + ' 分 ' + s + ' 秒'
    },
    Time() {
      this.timeCler = setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    async getBankInfo() {
      let res = await this.$axios.post('/api/Config/bankInfo')
      if (res.code === 1) {
        this.bankInfo = res.data
        if (this.bankInfo.length > 0) {
          this.currencyData = this.bankInfo[0]
          this.bankInfo.map((item) => {
            if (item.is_default == 1) {
              this.currencyData = item
            }
          })
          this.applyForm.pay_bank = this.currencyData.id
        }
      }
    },
    bankChange(val) {
      this.$nextTick(() => {
        this.bankInfo.map((item) => {
          if (item.id == val) {
            this.currencyData = item
          }
        })
      })
    },
    async paySet() {
      this.applyForm = {
        ...this.applyForm,
        ...this.payData,
      }
      this.nextLoading = true
      let res = await this.$axios.post('/api/Config/pay', this.applyForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
        this.$emit('updateData')
        this.showMask = false
      }
    },
    toReg() {
      this.$router.push({ name: 'Email' })
    },
  },
}
</script>

<style scoped lang="scss">
.el-select {
  width: 300px;
}
.form-lable {
  color: #999999;
}
.tip {
  font-size: 12px;
  color: #999999;
  padding-left: 20px;
  line-height: 20px;
  .addr {
    color: #409eff;
    cursor: pointer;
    margin-left: 6px;
  }
}
.downTime {
  color: red;
}
</style>
