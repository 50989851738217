var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversion"},[_c('div',{staticClass:"conversion-box"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('兑换')))]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"label-width":"110px","size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('my_conversion_2'),"prop":"email"}},[_c('el-radio-group',{on:{"change":_vm.typeChange},model:{value:(_vm.ruleForm.change_type),callback:function ($$v) {_vm.$set(_vm.ruleForm, "change_type", $$v)},expression:"ruleForm.change_type"}},[_c('el-radio-button',{attrs:{"label":"1"}},[_vm._v(_vm._s(_vm.$t('数字货币-->美金')))]),_c('el-radio-button',{attrs:{"label":"2"}},[_vm._v(" "+_vm._s(_vm.$t('美金-->数字货币'))+" ")])],1)],1),(_vm.ruleForm.change_type == 1)?_c('el-form-item',{attrs:{"rules":[
          {
            required: true,
            message: _vm.$t('my_conversion_6'),
            trigger: 'change',
          } ],"prop":"choice_curiency"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('my_conversion_5')))]),_c('el-tooltip',{staticClass:"box-item",attrs:{"effect":"dark","content":_vm.$t('选择您需要兑换的币种'),"placement":"top"}},[_c('i',{staticClass:"el-icon-question"})])]},proxy:true}],null,false,2547515411)},[_c('el-select',{attrs:{"placeholder":_vm.$t('my_conversion_7')},on:{"change":_vm.curiencyChange},model:{value:(_vm.ruleForm.choice_curiency),callback:function ($$v) {_vm.$set(_vm.ruleForm, "choice_curiency", $$v)},expression:"ruleForm.choice_curiency"}},_vm._l((_vm.currencyList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1):_c('el-form-item',{attrs:{"rules":[
          {
            required: true,
            message: _vm.$t('选择您需要兑换的币种'),
            trigger: 'change',
          } ],"label":_vm.$t('my_conversion_5'),"prop":"choice_curiency"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('选择您需要兑换的币种')},on:{"change":_vm.curiencyChange},model:{value:(_vm.ruleForm.choice_curiency),callback:function ($$v) {_vm.$set(_vm.ruleForm, "choice_curiency", $$v)},expression:"ruleForm.choice_curiency"}},_vm._l((_vm.currencyList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1),(_vm.ruleForm.choice_curiency)?_c('el-form-item',{staticClass:"exchange",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.$t('my_conversion_8'))+" ： "),_c('span',{staticClass:"exchange-cont"},[_vm._v(" "+_vm._s(_vm.ruleForm.choice_curiency)+" : "+_vm._s(_vm.$t('my_conversion_9'))+" = 1 : "+_vm._s(_vm.Rates)+" ")])]):_vm._e(),_c('el-form-item',{attrs:{"label":_vm.$t('my_conversion_10'),"rules":[
          {
            required: true,
            message: _vm.$t('my_conversion_11'),
            trigger: 'blur',
          } ],"prop":"gonglian"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('my_conversion_11')},on:{"change":_vm.gonglianChange},model:{value:(_vm.ruleForm.gonglian),callback:function ($$v) {_vm.$set(_vm.ruleForm, "gonglian", $$v)},expression:"ruleForm.gonglian"}},_vm._l((_vm.gonglianList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.chain,"value":item.chain}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('协议：')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请选择协议'),"disabled":""},model:{value:(_vm.ruleForm.agreement),callback:function ($$v) {_vm.$set(_vm.ruleForm, "agreement", $$v)},expression:"ruleForm.agreement"}})],1),(_vm.ruleForm.change_type == 1)?_c('el-form-item',{attrs:{"label":_vm.$t('my_conversion_12'),"rules":[
          {
            required: true,
            message: _vm.$t('请选择您用来收款的美金账户'),
            trigger: 'change',
          } ],"prop":"choice_account"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('请选择您用来收款的美金账户')},model:{value:(_vm.ruleForm.choice_account),callback:function ($$v) {_vm.$set(_vm.ruleForm, "choice_account", $$v)},expression:"ruleForm.choice_account"}},_vm._l((_vm.bankList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.bank_number,"value":item.id}})}),1)],1):_c('el-form-item',{attrs:{"label":_vm.$t('收款钱包地址：'),"rules":[
          {
            required: true,
            message: _vm.$t('请选择您用来收款的钱包地址'),
            trigger: 'change',
          } ],"prop":"choice_account"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('请选择您用来收款的钱包地址')},model:{value:(_vm.ruleForm.choice_account),callback:function ($$v) {_vm.$set(_vm.ruleForm, "choice_account", $$v)},expression:"ruleForm.choice_account"}},_vm._l((_vm.userWallet),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.chain_addr,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('my_conversion_15'),"rules":[
          {
            required: true,
            message: _vm.$t('my_conversion_16'),
            trigger: 'blur',
          } ],"prop":"amount"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('my_conversion_17')},on:{"input":_vm.amountBlur},model:{value:(_vm.ruleForm.amount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "amount", $$v)},expression:"ruleForm.amount"}},[_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.ruleForm.change_type == 1 ? '个' : '$'))])],2)],1),_c('el-form-item',{attrs:{"label":_vm.ruleForm.change_type == 1
            ? _vm.$t('my_conversion_18')
            : _vm.$t('实际到账数量：')}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.ruleForm.actual_num),callback:function ($$v) {_vm.$set(_vm.ruleForm, "actual_num", $$v)},expression:"ruleForm.actual_num"}},[_c('template',{slot:"append"},[_vm._v(" "+_vm._s(_vm.ruleForm.change_type == 2 ? '个' : '$')+" ")])],2)],1),_c('el-form-item',{attrs:{"label":_vm.$t('my_conversion_19')}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.ruleForm.amount_fee),callback:function ($$v) {_vm.$set(_vm.ruleForm, "amount_fee", $$v)},expression:"ruleForm.amount_fee"}},[_c('template',{slot:"append"},[_vm._v("$")])],2)],1),_c('div',{staticClass:"conversion-tip",class:_vm.isMonery ? 'red' : ''},[_vm._v(" "+_vm._s(_vm.$t('my_conversion_20'))+"：$"+_vm._s(_vm.surplusMoney)+" "),(_vm.isMonery)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v(" "+_vm._s(_vm.$t('my_conversion_21'))+" "),_c('span',{staticClass:"addr",on:{"click":function($event){return _vm.jumpTo('Enhance')}}},[_vm._v(" "+_vm._s(_vm.$t('my_conversion_22'))+" ")])]):_vm._e()]),_c('el-form-item',{attrs:{"label":""}},[_c('el-button',{staticClass:"form-login",attrs:{"loading":_vm.loading,"disabled":_vm.isMonery,"type":"primary"},on:{"click":_vm.submitPay}},[_vm._v(" "+_vm._s(_vm.$t('my_conversion_23'))+" ")])],1)],1)],1),_c('usdPayment',{attrs:{"payData":_vm.payData,"type":3,"isshow":_vm.isUsdPayment},on:{"update:isshow":function($event){_vm.isUsdPayment=$event},"updateData":_vm.updateDataFu}}),_c('currencyPayment',{attrs:{"payData":_vm.payData,"isshow":_vm.isCurrencyPayment},on:{"update:isshow":function($event){_vm.isCurrencyPayment=$event},"updateData":_vm.updateDataFu}}),_c('check-pay-password-dialog',{attrs:{"isshow":_vm.isCheckPayPasswordDialog},on:{"checkPayPassword":_vm.checkPayPassword,"update:isshow":function($event){_vm.isCheckPayPasswordDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }