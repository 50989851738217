<template>
  <div class="conversion">
    <div class="conversion-box">
      <div class="tit">{{ $t('兑换') }}</div>
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        label-width="110px"
        size="small"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('my_conversion_2')" prop="email">
          <el-radio-group v-model="ruleForm.change_type" @change="typeChange">
            <el-radio-button label="1">{{
              $t('数字货币-->美金')
            }}</el-radio-button>
            <el-radio-button label="2">
              {{ $t('美金-->数字货币') }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :rules="[
            {
              required: true,
              message: $t('my_conversion_6'),
              trigger: 'change',
            },
          ]"
          v-if="ruleForm.change_type == 1"
          prop="choice_curiency"
        >
          <template #label>
            <span>{{ $t('my_conversion_5') }}</span>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('选择您需要兑换的币种')"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <el-select
            v-model="ruleForm.choice_curiency"
            @change="curiencyChange"
            :placeholder="$t('my_conversion_7')"
          >
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :rules="[
            {
              required: true,
              message: $t('选择您需要兑换的币种'),
              trigger: 'change',
            },
          ]"
          v-else
          :label="$t('my_conversion_5')"
          prop="choice_curiency"
        >
          <el-select
            v-model="ruleForm.choice_curiency"
            @change="curiencyChange"
            :placeholder="$t('选择您需要兑换的币种')"
          >
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" v-if="ruleForm.choice_curiency" class="exchange">
          {{ $t('my_conversion_8') }} ：
          <span class="exchange-cont">
            {{ ruleForm.choice_curiency }} : {{ $t('my_conversion_9') }} = 1 :
            {{ Rates }}
          </span>
        </el-form-item>
        <el-form-item
          :label="$t('my_conversion_10')"
          :rules="[
            {
              required: true,
              message: $t('my_conversion_11'),
              trigger: 'blur',
            },
          ]"
          prop="gonglian"
        >
          <!-- <el-input
            v-model="ruleForm.gonglian"
            disabled
            :placeholder="$t('my_conversion_11')"
          >
          </el-input> -->
          <el-select
            v-model="ruleForm.gonglian"
            @change="gonglianChange"
            :placeholder="$t('my_conversion_11')"
          >
            <el-option
              v-for="(item, index) in gonglianList"
              :key="index"
              :label="item.chain"
              :value="item.chain"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('协议：')">
          <el-input
            :placeholder="$t('请选择协议')"
            v-model="ruleForm.agreement"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('my_conversion_12')"
          v-if="ruleForm.change_type == 1"
          :rules="[
            {
              required: true,
              message: $t('请选择您用来收款的美金账户'),
              trigger: 'change',
            },
          ]"
          prop="choice_account"
        >
          <el-select
            v-model="ruleForm.choice_account"
            :placeholder="$t('请选择您用来收款的美金账户')"
          >
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bank_number"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('收款钱包地址：')"
          v-else
          :rules="[
            {
              required: true,
              message: $t('请选择您用来收款的钱包地址'),
              trigger: 'change',
            },
          ]"
          prop="choice_account"
        >
          <el-select
            v-model="ruleForm.choice_account"
            :placeholder="$t('请选择您用来收款的钱包地址')"
          >
            <el-option
              v-for="item in userWallet"
              :key="item.id"
              :label="item.chain_addr"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('my_conversion_15')"
          :rules="[
            {
              required: true,
              message: $t('my_conversion_16'),
              trigger: 'blur',
            },
          ]"
          prop="amount"
        >
          <el-input
            v-model="ruleForm.amount"
            @input="amountBlur"
            :placeholder="$t('my_conversion_17')"
          >
            <template slot="append">{{
              ruleForm.change_type == 1 ? '个' : '$'
            }}</template>
          </el-input>
        </el-form-item>
        <el-form-item
          :label="
            ruleForm.change_type == 1
              ? $t('my_conversion_18')
              : $t('实际到账数量：')
          "
        >
          <el-input v-model="ruleForm.actual_num" disabled>
            <template slot="append">
              {{ ruleForm.change_type == 2 ? '个' : '$' }}
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('my_conversion_19')">
          <el-input v-model="ruleForm.amount_fee" disabled>
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <div class="conversion-tip" :class="isMonery ? 'red' : ''">
          {{ $t('my_conversion_20') }}：${{ surplusMoney }}
          <span style="margin-left: 6px" v-if="isMonery">
            {{ $t('my_conversion_21') }}
            <span class="addr" @click="jumpTo('Enhance')">
              {{ $t('my_conversion_22') }}
            </span>
          </span>
        </div>
        <el-form-item label="">
          <el-button
            :loading="loading"
            class="form-login"
            :disabled="isMonery"
            type="primary"
            @click="submitPay"
          >
            {{ $t('my_conversion_23') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <usdPayment
      :payData="payData"
      :type="3"
      :isshow.sync="isUsdPayment"
      @updateData="updateDataFu"
    ></usdPayment>
    <currencyPayment
      :payData="payData"
      :isshow.sync="isCurrencyPayment"
      @updateData="updateDataFu"
    ></currencyPayment>
    <check-pay-password-dialog
      @checkPayPassword="checkPayPassword"
      :isshow.sync="isCheckPayPasswordDialog"
    ></check-pay-password-dialog>
  </div>
</template>

<script>
import usdPayment from '@/components/pay/usdPayment.vue'
import currencyPayment from '@/components/pay/currencyPayment.vue'
import checkPayPasswordDialog from '@/components/pay/checkPayPasswordDialog.vue'
export default {
  name: 'Login',
  components: {
    usdPayment,
    currencyPayment,
    checkPayPasswordDialog,
  },
  data() {
    return {
      ruleForm: {
        change_type: '1',
        gonglian: '',
      },
      payData: {},
      isUsdPayment: false,
      isCurrencyPayment: false,
      exchangeRateData: {
        bank_desc: {},
      },
      bankList: [],
      surplusMoney: '',
      Rates: '',
      userWallet: [],
      currencyList: [],
      gonglianList: [],
      isMonery: false,
      loading: false,
      isCheckPayPasswordDialog: false,
      checkPayPasswordRes: 0,
    }
  },
  created() {
    this.getBank()
    this.getShengYu()
    this.getCurrency()
  },
  methods: {
    checkPayPassword(checkPayPassword) {
      this.checkPayPasswordRes = checkPayPassword
      this.conversionPay()
    },
    async getCurrency() {
      let res = await this.$axios.post('/api/Common/getCurrency')
      if (res.code === 1) {
        this.currencyList = res.data
      }
    },
    curiencyChange(val) {
      this.isMonery = false
      this.$set(this.ruleForm, 'amount', '')
      this.$set(this.ruleForm, 'amount_fee', '')
      this.$set(this.ruleForm, 'actual_num', '')
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
      this.getChain(val)
      this.getRates(val)
      if (this.ruleForm.change_type == 2) {
        this.getUserWallet(val)
      }
    },
    gonglianChange(val) {
      let result = this.gonglianList.find((item) => {
        return item.chain == val
      })
      this.ruleForm.agreement = result.agreement
    },
    async getChain(val) {
      let res = await this.$axios.post('/api/Exchange/getChain', {
        name: val,
      })
      if (res.code === 1) {
        this.gonglianList = res.data
        this.ruleForm.gonglian = ''
        this.ruleForm.agreement = ''
      }
    },
    async getRates(val) {
      let res = await this.$axios.post('/api/Exchange/getRates', {
        type: this.ruleForm.change_type,
        currency: val,
      })
      if (res.code === 1) {
        this.Rates = res.data.usd_rate
      }
    },
    async getUserWallet(val) {
      let res = await this.$axios.post('/api/Exchange/getUserWallet', {
        currency: val,
      })
      if (res.code === 1) {
        this.userWallet = res.data
      }
    },
    updateDataFu() {
      this.getBank()
      this.ruleForm = {
        change_type: this.ruleForm.change_type,
      }
      this.$refs.ruleForm.resetFields()
      this.$alert(this.$t('my_conversion_24'), this.$t('my_conversion_25'), {
        confirmButtonText: this.$t('my_conversion_26'),
      })
    },
    typeChange(val) {
      this.ruleForm = {
        change_type: val,
        gonglian: '',
      }
      this.isMonery = false
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    async amountBlur() {
      if (!this.ruleForm.agreement) {
        this.$message({
          message: this.$t('请选择协议'),
          type: 'warning',
        })
        this.ruleForm.amount = ''
        return
      }
      this.ruleForm.amount = this.ruleForm.amount.replace(/[^\d.]/g, '')
      if (!this.ruleForm.amount) {
        this.$set(this.ruleForm, 'amount_fee', 0)
        this.$set(this.ruleForm, 'actual_num', 0)
        this.isMonery = false
        return
      }
      let res = await this.$axios.post('/api/Exchange/Calculation', {
        account: this.ruleForm.amount,
        type: this.ruleForm.change_type,
        currency: this.ruleForm.agreement,
      })
      if (res.code === 1) {
        this.$set(this.ruleForm, 'amount_fee', res.data.shouxufei)
        this.$set(this.ruleForm, 'actual_num', res.data.real_account)
        if (res.data.show_status == 1) {
          this.isMonery = false
        } else {
          this.isMonery = true
        }
      }
    },
    async getBank() {
      let res = await this.$axios.post('/api/Exchange/getBank')
      if (res.code === 1) {
        this.bankList = res.data
      }
    },
    async getShengYu() {
      let res = await this.$axios.post('api/Exchange/shengYu')
      if (res.code === 1) {
        this.surplusMoney = res.data.shengyu
      }
    },
    submitPay() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isCheckPayPasswordDialog = true
          // if (this.checkPayPasswordRes == 1) {
          //   this.conversionPay()
          // } else {
          //   this.isCheckPayPasswordDialog = true
          // }
        }
      })
    },
    async conversionPay() {
      this.loading = true
      let res = await this.$axios.post('/api/Exchange/index', this.ruleForm)
      this.loading = false
      if (res.code === 1) {
        if (this.ruleForm.change_type == '1') {
          this.payData = {
            ...res.data,
            type: 3,
            choice_curiency: this.ruleForm.choice_curiency,
            currency: this.ruleForm.gonglian,
          }
          this.isUsdPayment = true
        } else {
          this.payData = {
            ...res.data,
            type: 4,
          }
          this.isCurrencyPayment = true
        }
        // if (this.ruleForm.amount_fee) {

        // } else {
        //   this.$message({
        //     message: res.msg,
        //     type: 'success',
        //   })
        //   this.updateDataFu()
        // }
      }
    },
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style scoped lang="scss">
.conversion {
  padding-bottom: 20px;
  width: 510px;
  margin: 0 auto;
  margin-top: 30px;
}
.conversion-box {
  margin-right: 50px;
  .tit {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 30px;
    text-align: center;
  }
  .el-select {
    width: 100%;
  }
  .exchange {
    font-size: 14px;
    .exchange-cont {
      font-size: 16px;
      font-weight: bold;
    }
    /deep/ .el-form-item__content {
      line-height: 20px;
    }
  }
  /deep/ .el-radio-group {
    width: 100%;
    .el-radio-button {
      width: 50%;
      .el-radio-button__inner {
        width: 100%;
      }
    }
  }
}
.conversion-tip {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  background: #bbbbbb;
  font-size: 12px;
  padding: 0 8px;
  text-align: center;
  margin-bottom: 20px;
  &.red {
    background: rgba(244, 11, 11, 100);
    color: #fff;
    .addr {
      color: #1ed699;
    }
  }
  .addr {
    color: #409eff;
    cursor: pointer;
    margin-left: 6px;
  }
}
.form-login {
  margin-left: 80px;
}
</style>
